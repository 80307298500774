// If you don't want to use TypeScript you can delete this file!
import * as React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../layouts/default"
import Seo from "../components/seo"
import tw, { styled } from 'twin.macro'

import { Cloud, Speedometer, Keyboard } from 'react-bootstrap-icons'

import logo from '../images/black-chip-logo.svg';
import logo_white from '../images/black-chip-logo--white.svg';

type DataProps = {
  data: object,
  path: string
}

const UsingTypescript: React.FC<PageProps> = ({ data, path }) => (
  <Layout>
    <Seo title="Sophisticated Cloud Software" description="Typescript application development studio, New Zealand" keywords="typescript, gatsby, nextjs, remix, react" />
    <Splash>
      <header>
      <strong style={{'--bg': `url("${logo}")`, '--bg-white': `url("${logo_white}")`}} tw="block mx-auto font-medium font-display text-4xl md:text-5xl xl:text-6xl tracking-tight antialiased flex gap-x-3 items-center">
        <span>BlackChip</span>
      </strong>
      <h1>Sophisticated Cloud Software, made in <mark>New Zealand</mark></h1>
      
      <ul>
        <li>
          <a href="https://twitter.com/BlackChipIO" rel="nofollow noopener noreferrer">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
              <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
            </svg>
            <span tw="sr-only">Follow us on Twitter</span>
          </a>
        </li>
        <li>
          <a href="https://www.linkedin.com/company/blackchip/" rel="nofollow noopener noreferrer">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
            <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/>
          </svg>
            <span tw="sr-only">Follow us on Linkedin</span>
          </a>
        </li>
      </ul>
      </header>
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" tw="inline absolute z-0">
      <defs>
        <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" style={{"stop-color":"#2dd4bf","stop-opacity":"1"}} />
          <stop offset="100%" style={{"stop-color":"#2563eb","stop-opacity":"1"}} />
        </linearGradient>
      </defs>
      </svg>
      <main>
        <div className="product">
        <h2>Product</h2>
          <ol>
            <li>
              <Cloud fill="url(#grad1)" />
              <p><Link className="underline-offset-4" to="/data">Data modelling</Link> through performant, robust, flexible, secure and highly-available API design.</p>
            </li>
            <li>
              <Speedometer fill="url(#grad1)" />
              <p>Fast, accessible, delightful interfaces and user experiences built with <Link className="underline-offset-4" to="/stack">modern stacks</Link>.</p>
            </li>
            <li>
              <Keyboard fill="url(#grad1)" />
              <p>User-friendly, extendible, intuitive <Link className="underline-offset-4" to="/cms">content managment</Link> for editors.</p>
            </li>
          </ol>
        </div>
      </main>
      
    </Splash>
  </Layout>
)

export default UsingTypescript

const Splash = styled.div`
  ${tw `min-h-screen flex flex-col items-center justify-start flex-wrap`}

  ::selection {
    background:red;
  }

  strong::before {
    ${tw `
      content
      width[1em] 
      height[1em] 
      block 
      bg-no-repeat 
      bg-cover 
      bg-center 
      backgroundImage[var(--bg)]
      dark:backgroundImage[var(--bg-white)]
    `}
  }

  strong span::after {
    ${tw `content["."]`}
  }

  header {
    ${tw `flex justify-center flex-col items-center gap-y-4 mt-10 lg:mt-16`}


    a {
      ${tw `block mt-10`}

      &[href*="twitter"] {
        ${tw ` hover:text-blue-500`}
      }
      &[href*="linkedin"] {
        ${tw ` hover:text-blue-400`}
      }
    }

    a svg {
      ${tw `w-8 h-auto align-top outline-none`}
    }
  }

  h1 {
    ${tw `
      text-center 
      font-display 
      font-light 
      text-2xl 
      antialiased
      max-w-[22ch]
    `}

    mark {
      ${tw `text-transparent bg-clip-text bg-gradient-to-br from-teal-400 to-blue-600
      `}
    }
  }
  
  ul {
    ${tw `flex items-center justify-center gap-x-6`}
  }


  main {
    ${tw `font-display font-light mt-20`}

    h2 {
      ${tw `font-light text-3xl`}
    }
  }

  .product {
    ${tw `mt-5 lg:mt-12`}

    > h2 {
      ${tw `tracking-widest uppercase text-xs dark:text-neutral-200 flex items-center`}

      &::before,
      &::after {
        ${tw `content h-px w-[calc(100% - 1rem)] bg-zinc-300 dark:bg-zinc-700`}
      }

      &::before {
        ${tw `mr-4`}
      }

      &::after {
        ${tw `ml-4`}
      }
    }

    ol {
      ${tw `list-none flex flex-wrap justify-center items-start gap-y-10 max-w-3xl gap-x-10 p-0 my-10`}

      li {
        ${tw `text-center w-full md:flex-1`}
      }

      svg {
        ${tw `w-16 h-16 align-top block mb-5 mx-auto`}
      }

      a {
        ${tw `underline`}
        text-underline-offset: .25em;
      }

      p {
        ${tw `leading-relaxed max-w-[30ch] mx-auto`}
      }
    }
  }
`